.container {
  padding: 90px 5px 0 5px;
  display: flex;
  flex: 1;
  padding-bottom: 40px;
  flex-direction: column;
  height: 100vh;
}

.loadingContainer {
  height: 95vh;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.section {
  display: flex;
  flex-direction: column;
}

.main {
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 40px;
  padding: 20px;
}

.imageBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400;
  background-color: aliceblue;
  border: 2px solid #000;
  box-shadow: 24;
  padding: 2rem 4rem 3rem 4rem;
}
.headerRow {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
}

.headerBtnContainer {
  display: flex;
  flex-direction: row;
  float: right;
  gap: 20px;
  padding-right: 10px;
}

.pagination {
  display: flex;
  width: 120px;
  padding: 0 10px;
  justify-content: space-between;
  align-self: flex-end;
  margin: 30px 30px 20px 0;
}

.customFileButton {
  padding: 14px 20px;
  background-color: #1ca0c4;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
  margin-bottom: 10px;
}

.customFileButton:hover {
  background-color: #1782a0;
}
