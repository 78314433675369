.container {
  display: flex;
  flex: 1;
  flex-direction: row;
  height: calc(100vh + (0.49 * 100vh));
}

.sectionLeft {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  background-color: #eaeaea;
}

.logo {
  margin-top: 30px;
  margin-left: 30px;
}

.coverImg {
  align-self: center;
}

.sectionRight {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.textContainer {
  position: absolute;
  top: 20px;
  right: 25px;
  font-weight: bold;
  font-size: 18px;
}

.spanTxt {
  cursor: pointer;
  color: #1ca0c4;
}

.inputContainer {
  width: 550px;
}

.input {
  margin-bottom: 20px;
}

.button {
  width: 95.5%;
  margin-left: 7px !important;
  height: 50px;
}

.tickButton {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.tick {
  margin-bottom: 20px;
  margin-left: 5px;
}
