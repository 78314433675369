.container {
  padding: 80px 5px 0 5px;
  display: flex;
  flex: 1;
}

.loadingContainer {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.main {
  width: 100%;
  overflow-x: auto;
}
