.container {
  padding: 74px 0px 0 0px;
  display: flex;
  flex: 1;
  height: 100vh;
  flex-direction: column;
}

.topBar {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  background-color: #e9e9e9;
  color: #9a0000;
}

.main {
  width: 100%;
  overflow-x: auto;
}

.th:nth-child(1),
.td:nth-child(1) {
  width: 20px !important;
}

.th:nth-child(2),
.td:nth-child(2) {
  width: 80px;
  text-align: center;
}

.th:nth-child(3),
.td:nth-child(3) {
  width: 400px;
}
