.container {
  display: flex;
  flex: 1;
  justify-content: center;
  margin-top: 80px;
}

.loadingContainer {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.main {
  gap: 20px;
  width: 98%;
  margin-top: 16px;
  overflow-x: auto;
  padding-top: 20px;
  margin-bottom: 20px;
}

.requiredContainer {
  display: grid;
  grid-template-columns: auto auto;
  gap: 30px;
  border: none;
  margin: 10px 0;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 50px;
  background-color: #1ca0c4;
  color: white;
  font-size: 18px;
  padding: 10px 18px;
  border-radius: 20px;
}

.btn:hover {
  cursor: pointer;
  background-color: #17738d;
}

.btn2 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 200px;
  background-color: black;
  color: white;
  font-size: 18px;
  padding: 10px 18px;
  border-radius: 20px;
}
.btn2:hover {
  cursor: pointer;
  background-color: #424242;
}
