body,
html {
  margin: 0;
  padding: 0;
  zoom: 82%;
}

#headingContent {
  max-width: 150px;
}

table {
  border-collapse: collapse;
  width: 100%;
  box-shadow: 0px 1px 3px 0px #a7a4a4 !important;
  margin-bottom: 10px;
}

thead {
  height: 40px;
  box-shadow: 0px 1px 3px 0px #cccccc !important;
}

thead :not(:first-child) {
  text-align: left;
}

tbody td:first-child {
  text-align: center;
}

tbody tr {
  height: 40px;
}

th span:nth-child(2) {
  float: right;
}

td,
th {
  padding: 10px;
  border-right: 3px dotted #c0c0c0 !important;
}

tbody td {
  border-bottom: 0px solid black !important;
}

tbody tr:nth-child(even) {
  background-color: #f0f0f0 !important;
}

tbody tr:last-child {
  border-bottom: 1px solid #e0e0e0;
}

#sno {
  text-align: center;
}

.attachment-cell {
  position: relative;
  cursor: pointer;
  color: #1ca0c4;
  text-align: center;
}

.attachment-count::after {
  width: 250px;
  content: attr(
    data-attachments
  ); /* Use the 'data-attachments' attribute to store attachment names */
  position: absolute;
  top: 50%; /* Center the tooltip vertically */
  right: 60%; /* Position the tooltip to the left */
  transform: translate(-8px, -50%); /* Adjust the translation for spacing */
  background-color: white; /* Background color */
  border: 1px solid #1ca0c4; /* Border color */
  color: #000;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 16px; /* Increased font size */
  white-space: normal; /* Allow line breaks */
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s;
}

.attachment-count:hover::after {
  opacity: 1;
}

.attachment-count:hover::before {
  content: ""; /* Create an empty content for line breaks */
  display: block;
  height: 0.5em; /* Adjust this value for desired spacing between lines */
}
