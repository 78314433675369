.container {
  display: flex;
  flex: 1;
  height: calc(100vh + (0.49 * 100vh));
}

.main {
  display: flex;
  flex: 1;
  background-image: url("../../Assets/background.png");
  padding: 40px;
  flex-direction: column;
  background-size: cover;
  justify-content: center;
  align-items: center;
}

.logoImg {
  width: 220px;
  height: 90px;
  margin-bottom: 50px;
  margin-top: -200px;
}

.formContainer {
  align-self: center;
  align-items: center;
  width: 477px;
  display: flex;
  flex-direction: column;
}

.subHeading {
  font-size: 26px;
  color: #0d0d0d;
  align-self: center;
  line-height: 0;
  font-weight: bold;
}

.passInputContainer {
  margin: 10px auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  position: absolute;
  right: 10px;
  top: 14px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon:hover {
  cursor: pointer;
}

.formInput:focus + .icon {
  color: white;
}

.formInput {
  height: 50px;
  width: 395px;
  border-radius: 10px;
  border: 1px solid #d9d9d9;
  background-color: #f5f5f5;
  font-size: 20px;
  padding: 0 50px 0 10px;
  margin-bottom: 10px;
}

.formInput:focus {
  border: 1px solid #d9d9d9;
  color: white;
  background-color: #3d3c3c;
}

.formText {
  margin-top: 40px;
  font-weight: 400;
  font-size: 22px;
  line-height: 0;
  font-weight: bold;
}

.btn {
  width: 200px;
  height: 52px;
  background-color: #1ca0c4;
  border: 1px solid #d9d9d9;
  border-radius: 30px;
  font-size: 24px;
  font-weight: 600;
  color: #fffefe;
  margin-top: 30px;
  align-self: center;
}

.btn:hover,
.btn2:hover {
  cursor: pointer;
  background-color: #157e9b;
}

.btn2 {
  width: 200px;
  height: 52px;
  border-radius: 30px;
  font-size: 24px;
  font-weight: 600;
  color: #fffefe;
  margin-top: 30px;
  background-color: #000;
  border: 1px solid #d9d9d9;
}

.coverImg {
  align-self: center;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
}

.footerImg {
  margin-bottom: -5px;
  height: 170px;
  width: 100%;
}
