.container {
  padding: 90px 5px 0 5px;
  display: flex;
  flex: 1;
}

.loadingContainer {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.main {
  width: 100%;
  overflow-x: auto;
}

.pagination {
  display: flex;
  width: 120px;
  float: right;
  margin-bottom: 20px;
  padding: 0 10px;
  justify-content: space-between;
}

.headerRow {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
}

.headerBtnContainer {
  display: flex;
  flex-direction: row;
  float: right;
  gap: 20px;
  padding-right: 10px;
}
