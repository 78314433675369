.container {
  display: flex;
  flex: 1;
  justify-content: center;
  margin-top: 83px;
  padding: 0px 80px;
  flex-direction: row;
  height: calc(100vh + (0.25 * 100vh));
}

.section1 {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.section2 {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 0 200px;
}

.notice {
  font-weight: 400;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
}

.inp {
  background-color: transparent;
  color: inherit;
  height: 36px;
  border: 2px solid rgba(28, 160, 196, 1);
  box-sizing: border-box;
  padding: 0px 10px 0px 10px;
  font-size: 18px;
  width: 420px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  outline: none;
}

.inp:focus {
  border: 2px solid rgba(28, 160, 196, 1);
}

.inpTxt {
  font-size: 20px;
  line-height: 0;
}

.btnContainer {
  display: flex;
  flex-direction: row;
  gap: 130px;
  margin-top: -20px;
}

.btn1 {
  width: 150px;
  height: 52px;
  border-radius: 30px;
  font-size: 24px;
  font-weight: 600;
  color: #fffefe;
  margin-top: 30px;
  background-color: rgba(28, 160, 196, 1);
  border: 1px solid #d9d9d9;
}

.btn2 {
  width: 150px;
  height: 52px;
  border-radius: 30px;
  font-size: 24px;
  font-weight: 600;
  color: #fffefe;
  margin-top: 30px;
  background-color: black;
  border: 1px solid #d9d9d9;
}

.btn1:hover,
.btn2:hover {
  cursor: pointer;
  background-color: rgb(20, 120, 148);
}
