.container {
  padding: 75px 0px 50px 0px;
  display: flex;
  flex: 1;
}

.loadingContainer {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.main {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.header {
  display: flex;
  background-color: #d9d9d9;
  height: 40px;
  align-items: center;
  padding: 8px 20px;
  font-weight: 550;
  font-size: 18.5px;
}

.inputContainer {
  display: flex;
  flex-direction: row;
}

.inputSection {
  display: flex;
  flex: 1;
  padding: 0 70px 20px 70px;
  flex-direction: column;
  justify-content: center;
}

.inputSection > span {
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin-top: 20px;
  align-items: center;
}

.inputSection > span:nth-child(2) {
  align-items: flex-end;
}

.label {
  font-size: 18px;
  font-weight: bold;
}

.input {
  width: 350px;
  height: 40px;
  border: 1.5px solid #1ca0c4;
  font-size: 17.5px;
  padding: 0 10px;
}

.textarea {
  font-size: 17.5px;
  padding: 8px 8px;
  width: 350px;
  border: 1.5px solid #1ca0c4;
}

.footer {
  display: flex;
  flex: 1;
  flex-direction: row;
  background-color: #d9d9d9;
  margin-top: 10px;
}

.footer {
  background-color: #d9d9d9;
}

.inputContainerFooter {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  background-color: #d9d9d9;
}

.btnContainer {
  display: flex !important;
  flex: 1 !important;
  flex-direction: row !important;
  justify-content: flex-start !important;
  width: 450px;
  gap: 40px !important;
  align-self: flex-end;
}

.btnContainer > button {
  padding: 8px 18px;
  border: 1px solid #1ca0c4;
  border-radius: 20px;
  background-color: #1ca0c4;
  color: white;
  font-size: 18px;
  cursor: pointer;
}

/* Hide the default file input styles */
input[type="file"] {
  display: none;
}

/* Style the custom file upload button */
.fileUploadButton {
  margin-left: 175px;
  display: inline-block;
  padding: 10px 20px;
  background-color: #3498db;
  color: #fff;
  border: none;
  border-radius: 20px;
  cursor: pointer;
}

/* Optional: Add hover and focus styles for better user feedback */
.fileUploadButton:hover,
.fileUploadButton:focus {
  background-color: #2980b9;
}
